import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../Layout';
import Meta from '../basics/Meta';

import Header from '../basics/header';
import SubHeader from '../basics/sub-header';
import Blogs from '../basics/blogs';
import BorderColumns from '../basics/border-columns';
import ClientWin from '../basics/client-win';
import Stats from '../basics/stats';
import Spacer from '../basics/spacer';
import Contact from '../basics/contact';
import Video from '../basics/video';
import FullscreenVideo from '../basics/fullscreen-video';

import previewImg from './../../../img/services/video-poster/demo-reel.jpg';

const ServiceTemplate = ({ data }) => {
  const pageData = data.videoPhotoAnimationPageHJson;
  const siteURL = data.site.siteMetadata.siteUrl;
  return (
    <Layout useClass="services-layout">
      <Meta
        title={pageData.Meta.title}
        desc={pageData.Meta.desc}
        ogTitle={pageData.Meta.ogTitle}
        ogDesc={pageData.Meta.ogDesc}
        ogImg={siteURL + pageData.Meta.ogImg.publicURL}
      />
      <Header
        title={pageData.Header.title}
        subTitle={pageData.Header.subTitle}
        subTitle2={pageData.Header.subTitle2}
        bgImage={pageData.Header.bgImage}
        hexLettersImage={pageData.Header.hexLettersImage.publicURL}
        subTextHtml={pageData.Header.subTextHtml}
      />
      <SubHeader html={pageData.SubHeader.html} />
      <Spacer />
      <div style={{ margin: '0px auto -80px auto', maxWidth: '1148px', paddingLeft: '10px', paddingRight: '10px' }}>
        <FullscreenVideo mapUrl="/videos/demo-reel/video-v20201216-02.m3u8" previewImg={previewImg} />
      </div>

      <BorderColumns
        col="3"
        title={pageData.BorderColumns.title}
        data={pageData.BorderColumns.data}
        extraPaddingTop={60}
      />
      <Spacer />
      <ClientWin
        title={pageData.ClientWin.title}
        html={pageData.ClientWin.html}
        bgImg={pageData.ClientWin.bgImg.publicURL}
        videoRight={pageData.ClientWin.videoRight}
        videoPreviewImg={pageData.ClientWin.videoPreviewImg.publicURL}
        hideResultsText={true}
        hideStats={false}
      />
      <Stats />
      {/* <Stats stats={pageData.Stats} /> */}
      {/* <Spacer /> */}
      <Contact title={pageData.Contact.title} text={pageData.Contact.text} />
      <Blogs title={pageData.Blogs.title} blogPosts={data.allWpPost.edges} />
    </Layout>
  );
};
export default ServiceTemplate;

export const pageQuery = graphql`
  query ($catSlug: String!) {
    allWpPost(
      limit: 3
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $catSlug } } } } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 360, height: 156, transformOptions: { cropFocus: CENTER })
                }
              }
            }
          }
        }
      }
    }

    videoPhotoAnimationPageHJson {
      Meta {
        title
        desc
        ogTitle
        ogDesc
        ogImg {
          publicURL
        }
      }
      Blogs {
        title
      }
      ClientWin {
        html
        title
        bgImg {
          publicURL
        }
        videoRight
        videoPreviewImg {
          publicURL
        }
      }
      BorderColumns {
        title
        data {
          html
          title
        }
      }
      Contact {
        text
        title
      }
      Header {
        subTextHtml
        subTitle
        subTitle2
        title
        bgImage {
          childImageSharp {
            gatsbyImageData(
              breakpoints: [500, 750, 1440, 1920, 2400]
              layout: FULL_WIDTH
              quality: 80
              placeholder: NONE
            )
          }
        }
        hexLettersImage {
          publicURL
        }
      }
      Stats {
        label
        suffix
        number
      }
      SubHeader {
        html
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
